<template>
  <div class="app-form">
    <el-form
      ref="formMedecin"
      :model="form"
      label-width="137px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row style="max-width: 680px">
        <el-col :span="12">
          <el-form-item label="Nom" prop="nom">
            <el-input v-model="form.nom"></el-input>
          </el-form-item>
          <el-form-item label="Prénom" prop="prenom">
            <el-input v-model="form.prenom"></el-input>
          </el-form-item>
          <el-form-item label="Date de naissance" prop="naissance_date">
            <el-date-picker
              v-model="form.naissance_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="Nationalité" prop="id_pays">
            <el-select
              v-model="form.id_pays"
              clearable
              filterable
              placeholder="Choisissez un pays"
            >
              <el-option
                v-for="item in paysOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="No CHUV" prop="no_employe">
            <el-input v-model="form.no_employe"></el-input>
          </el-form-item>
          <el-form-item label="Email privé" prop="email_prive">
            <el-input v-model="form.email_prive"></el-input>
          </el-form-item>
          <el-form-item label="Email CHUV" prop="email_chuv">
            <el-input v-model="form.email_chuv"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Tél. privé" prop="tel_prive">
            <el-input v-model="form.tel_prive"></el-input>
          </el-form-item>
          <el-form-item label="Mobile privé" prop="mobile_prive">
            <el-input v-model="form.mobile_prive"></el-input>
          </el-form-item>
          <el-form-item label="Tél. CHUV" prop="tel_chuv">
            <el-input v-model="form.tel_chuv"></el-input>
          </el-form-item>
          <el-form-item label="Rue" prop="ad_rue1">
            <el-input v-model="form.ad_rue1"></el-input>
          </el-form-item>
          <el-form-item label="Rue (suite)" prop="ad_rue2">
            <el-input v-model="form.ad_rue2"></el-input>
          </el-form-item>
          <el-form-item label="Code postal" prop="ad_cp">
            <el-input v-model="form.ad_cp"></el-input>
          </el-form-item>
          <el-form-item label="Ville" prop="ad_ville">
            <el-input v-model="form.ad_ville"></el-input>
          </el-form-item>
          <el-form-item label="Pays" prop="ad_id_pays">
            <el-select
              v-model="form.ad_id_pays"
              clearable
              filterable
              placeholder="Choisissez un pays"
            >
              <el-option
                v-for="item in paysOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var valideNoEmploye = (rule, value, callback) => {
      if (value) {
        const valStr = value.toString().trim();
        if (/^[0-9]+$/.test(valStr)) {
          callback();
        } else {
          callback(new Error("Le numéro doit être un nombre entier"));
        }
      } else {
        callback();
      }
    };
    return {
      rules: {
        nom: [
          {
            required: true,
            whitespace: true,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
        prenom: [
          {
            required: true,
            whitespace: true,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
        //naissance_date: [{required: true, message: 'Ce champ est obligatoire', trigger: 'change'}],
        no_employe: [{ validator: valideNoEmploye, trigger: "blur" }],
        email_prive: [
          {
            pattern: "[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
            message: "l'adresse email n'est pas valide",
            trigger: "blur",
          },
        ],
        email_chuv: [
          {
            pattern: "^ *$|^ *[A-Z0-9a-z._+-]+@chuv.ch *$",
            message: "Saisir une adresse @chuv.ch",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.medl.medecin;
      },
      set(form) {
        this.$store.commit("medl/setMedecin", form);
      },
    },
    paysOptions() {
      return this.$store.getters["paysl/paysSelectSansEu"];
    },
  },
};
</script>
