<template>
  <el-main class="rh-form">
    <h1>Ajouter un médecin</h1>
    <form-medecin ref="formMed"></form-medecin>
    <div>
      <el-button type="primary" :disabled="isSaving" @click="onSubmit('toEdit')"
        >Enregistrer</el-button
      >
      <el-button type="primary" :disabled="isSaving" @click="onSubmit('toList')"
        >Enregistrer et retourner à la liste</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </el-main>
</template>
<script>
import FormMedecin from "@/components/rh/ui/form/FormMedecin";
import { trimStringInObj, strNormalize } from "@/fonctions";

export default {
  components: { "form-medecin": FormMedecin },

  data() {
    return {
      isSaving: false,
    };
  },
  created() {
    this.$store.commit("medl/resetMedecin");
  },
  computed: {
    medecinCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.medl.medecinCreateStatus;
    },
  },
  methods: {
    onSubmit(btUsed) {
      this.submitBtUsed = btUsed;
      this.$refs.formMed.$refs.formMedecin.validate((valid) => {
        if (valid) {
          var medata = this.$store.state.medl.medecin;

          // enlever les espaces avant et après dans les string
          trimStringInObj(medata);

          // vérifie si la personne est  déjà dans la bd
          var MedsInBD = this.$store.getters["medl/MedsInBD"];
          var noChuv = parseInt(medata.no_employe);
          var nosChuvBD = this.$store.getters["medl/nosChuv"];
          if (
            MedsInBD.filter(
              (m) =>
                m[1] == strNormalize(medata.nom) &&
                m[2] == strNormalize(medata.prenom) &&
                m[3] == medata.naissance_date
            ).length > 0
          ) {
            const alertMsg = medata.naissance_date
              ? "<b>" +
                medata.nom +
                " " +
                medata.prenom +
                "</b> né le <b>" +
                medata.naissance_date +
                "</b> est déjà inscrit dans la base de données !"
              : "<b>" +
                medata.nom +
                " " +
                medata.prenom +
                "</b> est déjà inscrit dans la base de données ! S'il s'agit d'un homonyme, renseignez la <b>date de naissance</b>.";
            this.$alert(alertMsg, "Attention", {
              confirmButtonText: "Fermer",
              dangerouslyUseHTMLString: true,
            });
            return false;
          } else if (noChuv && nosChuvBD) {
            // vérifie si le No CHUV est déjà dans la BD
            if (nosChuvBD.filter((m) => m[1] == noChuv).length > 0) {
              this.$alert(
                "Ce 'No CHUV' est déjà attribué à une autre personne dans la base de données !",
                "Attention",
                {
                  confirmButtonText: "Fermer",
                }
              );

              return false;
            } else {
              // si plus d'erreur lance la création du nouvel enregistrement
              this.$store.dispatch("medl/createMedecin", medata);
            }
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("medl/createMedecin", medata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des médecins
      this.$router.push({ name: "medecins" });
    },
  },
  watch: {
    medecinCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.medecinCreateStatus == 2) {
        var medata = this.$store.state.medl.medecin;
        // message succès
        this.$message({
          message:
            medata.nom +
            " " +
            medata.prenom +
            " a été ajouté-e à la base de donnée",
          type: "success",
        });

        if (this.submitBtUsed == "toList") {
          // retourne à la liste des médecins
          this.$router.push({ name: "medecins" });
        } else if (this.submitBtUsed == "toEdit") {
          // va à la page du médecin créé
          this.$router.push({
            name: "donnees_perso",
            params: { idmed: this.$store.state.medl.medecinLastCreatedId },
          });
        }
      } else if (this.medecinCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.medecinCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.medecinCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
